// import Test from "pages/Test/Test/Test";
import ClientList from '../components/ClientList/ClientList';
import Flowers from '../components/Flowers/Flowers';
import Products from '../components/Products/Products';
import ProductsAdd from '../components/ProductsAdd/ProductsAdd';
import ProductsDetails from '../components/ProductsDetails/ProductsDetails';
import ProductsEdit from '../components/ProductsEdit/ProductsEdit ';
import Requests from '../components/Requests/Requests';
import RequestsDetails from '../components/RequestsDetiles/RequestsDetails';
import Restaurant from '../components/Restaurant/Restaurant';
import RestaurantAdd from '../components/Restaurant/RestaurantAdd/RestaurantAdd';
import RestaurantDetails from '../components/Restaurant/RestaurantDetails/RestaurantDetails';
import RestaurantEdit from '../components/Restaurant/RestaurantEdit/RestaurantEdit';
import EventList from '../components/ِEventList/EventList';
import ImagePage from '../components/ImagesPage/ImagePage';

const NavbarRoutes = [
  {
    path: 'products',
    element: <Products />,
  },
  {
    path: 'add/products',
    element: <ProductsAdd category="products" />,
  },
  {
    path: 'products/details/:Id',
    element: <ProductsDetails />,
  },
  {
    path: 'product/edit/:IdEdit',
    element: <ProductsEdit category="product" />,
  },
  {
    path: 'product/customer/edit/:IdOrder/:IdEdit',
    element: <ProductsEdit category="customer" />,
  },
  {
    path: 'restaurant',
    element: <Restaurant />,
  },
  {
    path: 'restaurant/edit/:IdEdit',
    element: <RestaurantEdit />,
  },
  {
    path: 'add/restaurant',
    element: <RestaurantAdd />,
  },
  {
    path: 'restaurant/details/:Id',
    element: <RestaurantDetails />,
  },
  {
    path: 'flowers',
    element: <Flowers />,
  },
  {
    path: 'add/flowers',
    element: <ProductsAdd category="flowers" />,
  },
  {
    path: 'add/customer-product/:OrderNumber',
    element: <ProductsAdd category="customer" />,
  },
  {
    path: 'flowers/details/:IdDetailsFlower',
    element: <ProductsDetails category="flower" />,
  },
  {
    path: 'flowers/edit/:IdEditFlower',
    element: <ProductsEdit category="flower" />,
  },
  {
    path: 'requests',
    element: <Requests />,
  },
  {
    path: 'requests/details/:IdRequest',
    element: <RequestsDetails />,
  },
  {
    path: 'events',
    element: <EventList />,
  },
  {
    path: 'clients',
    element: <ClientList />,
  },
  {
    path: 'images',
    element: <ImagePage />,
  },
];

export default NavbarRoutes;
