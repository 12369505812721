import { useEffect, useState } from 'react';
import './RequestsDetails.scss';
import { useNavigate, useParams } from 'react-router';
import { Button, Select, message, Typography } from 'antd';
import RequestsDetailsInfo from './RequestsDetailsInfo/RequestsDetailsInfo';
import {
  RequestsDetilesInfoLoading,
  RequestsDetilesLoading,
} from '../Loading/Loading';
import EditPrice from './EditRequestPrice/EditPrice';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { getOrdersDetails, updateOrder } from '../../network/order';
import { queryKeys } from '../../services/react-query/queryKeys';
import dayjs from 'dayjs';
import img from '../../assets/images/Vector.png';
import { Link } from 'react-router-dom';
import { LeftOutlined } from '@ant-design/icons';
const RequestsDetiles = () => {
  const client = useQueryClient();
  const { IdRequest } = useParams();
  const navigate = useNavigate();
  const [selectOptions, setSelectOptions] = useState({});
  const [readMore, setReadMore] = useState(false);
  console.log(selectOptions, 'selectOptions');
  const [handleEdit, setHandleEdit] = useState(null);
  const {
    data: orderDetails,
    isLoading: isLoadingOrders,
    isFetching: isFetchingOrders,
  } = useQuery([queryKeys.orderDetails], () => getOrdersDetails(IdRequest));
  // console.log(orderDetails, 'orderDetails');
  const orderItems =
    orderDetails?.model?.orderItems?.map((item) => item?.product) || {};
  const { mutate: updateOrders } = useMutation({
    mutationFn: updateOrder,
    onSuccess: (res) => {
      console.log('  res onSuccess :', res);
      if (res) {
        message.success('تم تعديل الطلب بنجاح');
        client.invalidateQueries([queryKeys.orderDetails]);
      } else {
        message.error('حدث خطأ ما');
      }
    },
    onError: (error) => {
      console.log('error :', error);
      message.error('حدث خطأ ما الرجاء المحاولة مرة اخرى');
    },
  });
  const orderStatus = orderDetails?.model?.orderStatus + 1 || 0;
  useEffect(() => {
    if (orderStatus) {
      const initialOptions = {};
      orderItems.forEach((item) => {
        initialOptions[item.id] = orderStatus;
      });
      const restaurantItems =
        orderDetails?.model?.reservations?.map((item) => item) || [];
      restaurantItems.forEach((item) => {
        if (item?.restaurant?.id) {
          initialOptions[item?.restaurant?.id] = orderStatus;
        }
      });
      setSelectOptions(initialOptions);
    }
  }, [orderStatus, orderDetails]);
  const handleChange = (value, itemId) => {
    setSelectOptions((prevOptions) => ({
      ...prevOptions,
      [itemId]: value,
    }));
    // setSelectOptions(value, itemId);

    // console.log('value :', value - 1, 'itemId :', itemId);
    const body = {
      id: IdRequest,
      orderStatus: value - 1,
    };
    updateOrders(body);
  };

  const dataShopping = Array.isArray(orderItems)
    ? orderItems.map((item) => ({
        id: item?.id || 'غير محدد',
        title: item?.productType === 0 ? 'شراء الهدية' : 'باقة الورد',
        name: item?.name || 'غير محدد',
        // payment: "تحت الاجراء",
        payment: orderStatus,
        image: item?.giftImages[0]?.imageURL || 'غير محدد',
        color: item?.color || 'غير محدد',
        size: item?.size || 'غير محدد',
        price: item?.price || 'غير محدد',
        // description : item?.description,
      }))
    : [];
  const restaurants =
    orderDetails?.model?.reservations?.map((item) => item) || {};
  const restaurant = Array.isArray(restaurants)
    ? restaurants.map((item) => ({
        history: dayjs(item?.reservationTime).format('YYYY-MM-DD'),
        number: item?.personsCount || 'غير محدد',
        title: 'حجز المطعم' || 'غير محدد',
        payment: orderStatus,
        // time: dayjs(item?.reservationTime).locale("ar").format("h:mm "),
        time: dayjs(item?.reservationTime).format('h:mm'),
        pm: dayjs(item?.reservationTime).format('a'),
        items: item?.restaurant
          ? {
              id: item?.restaurant?.id,
              name: item?.restaurant?.name || 'غير محدد',
              image: item?.restaurant?.giftImages[0]?.imageURL || 'غير محدد',
            }
          : {},
      }))
    : [];

  const externalProducts = orderDetails?.model?.externalProducts;
  const specialProducts = orderDetails?.model?.specialProducts;
  console.log(specialProducts, 'specialProducts');
  const getDay = (pm) => {
    switch (pm) {
      case 'pm':
        return 'مساء';
      case 'am':
        return 'صباحاً';
      default:
        return;
    }
  };
  const getOrderStatusStyle = (order) => {
    let background = '';
    let color = '';
    let text = '';
    switch (order) {
      case 1:
        text = 'قيد المراجعة';
        background = '#EE75011A';
        color = '#EE7501';
        break;
      case 2:
        text = 'تمت المراجعة';
        background = '#40C1AC1A';
        color = '#167767';
        break;
      case 3:
        text = 'قيد الانتظار';
        background = '#EE75011A';
        color = '#EE7501';
        break;
      case 4:
        text = 'مدفوع';
        background = '#40C1AC1A';
        color = '#167767';
        break;
      case 5:
        text = 'مكتمل';
        background = '#40C1AC1A';
        color = '#167767';
        break;
      case 6:
        text = ' تم الالغاء بواسطة العميل ';
        background = '#EB59591A';
        color = '#EB5959';
        break;
      case 7:
        text = ' تم الإلغاء بواسطة المسؤل ';
        background = '#EB59591A';
        color = '#EB5959';
        break;
      default:
        text = 'حاله الطلب';
        break;
    }
    return { background, color, text };
  };

  const options = [
    { value: 1, label: 'قيد المراجعة' },
    { value: 2, label: 'تمت المراجعة' },
    { value: 3, label: 'قيد الانتظار' },
    { value: 4, label: 'مدفوع' },
    { value: 5, label: 'مكتملة' },
    { value: 6, label: 'تم الالغاء بواسطة العميل' },
    { value: 7, label: 'تم الالغاء بواسطة المسؤل' },
    // { value: "تعديل المنتج", label: <EditPrice/>},
  ];
  if (isLoadingOrders || isFetchingOrders) {
    return (
      <>
        <RequestsDetilesInfoLoading />
        <RequestsDetilesLoading />
      </>
    );
  }
  return (
    <>
      <div className="requests-detiles">
        <div className="requests-detiles-header">
          <div className="header-product">
            <Link className="header-title" to="/requests">
              الطلبات
            </Link>
          </div>
          <div className="sub-title-page">
            <p>
              <span> تفاصيل الطلب </span> <LeftOutlined />
              <span> الطلبات </span>
            </p>
          </div>
        </div>
        <RequestsDetailsInfo orderDetails={orderDetails} />
        {orderDetails?.model?.orderStatus === 7 &&
        specialProducts?.length > 0 ? (
          <div className="requests-detiles-shop">
            {specialProducts?.map((item) => (
              <div key={item?.id} className="shop-card-info">
                <div className="shop-card-header">
                  <h3 className="card-header-title">شراء الهدية</h3>
                  <Button
                    onClick={() =>
                      navigate(
                        `/product/customer/edit/${orderDetails?.model?.id}/${item?.id}`
                      )
                    }
                    type="link"
                    style={{
                      fontSize: '18px',
                      fontWeight: '600',
                    }}
                  >
                    تعديل المنتج
                  </Button>
                  {/* <h3
                    style={{
                      fontSize: '15px',
                      fontWeight: '500',
                      background: '#016EEE1A',
                      color: '#016EEE',
                      padding: '5px 10px',
                      borderRadius: '5px',
                      width: '200px',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                  >
                    لم أجد هدية مناسبة
                  </h3> */}
                </div>
                <div className="shop-card-data">
                  <>
                    <div className="shop-card-image">
                      <img src={item?.giftImages[0].imageURL} alt="" />
                    </div>
                    <div className="shop-data-items">
                      <div className="shop-data-item">
                        <h4 className="data-item-title">الاسم:</h4>
                        <p className="data-item-text">{item?.name}</p>
                      </div>
                      <div className="shop-data-item">
                        <h4 className="data-item-title">سعر الهدية:</h4>
                        <p className="data-item-text">SR {item?.price}</p>
                      </div>
                      {item?.color && item?.color !== 'undefined' && (
                        <div className="shop-data-item">
                          <h4 className="data-item-title">اللون:</h4>
                          <p className="data-item-text">{item?.color}</p>
                        </div>
                      )}
                      {item?.size && item?.size !== 'undefined' && (
                        <div className="shop-data-item">
                          <h4 className="data-item-title">المقاس:</h4>
                          <p className="data-item-text">{item?.size}</p>
                        </div>
                      )}
                      {item?.description &&
                        item?.description !== 'undefined' && (
                          <div className="shop-data-item item-description">
                            <h4 className="data-item-title">الوصف:</h4>
                            <p className="data-item-text">
                              {item?.description}
                            </p>
                          </div>
                        )}
                    </div>
                  </>
                </div>
              </div>
            ))}
            {/* <Button
              type="primary"
              onClick={() =>
                navigate(
                  `/add/customer-product/${orderDetails?.model?.orderNumber}`
                )
              }
            >
              اضافة طلب اخر
            </Button> */}
          </div>
        ) : (
          orderDetails?.model?.orderStatus === 7 && (
            <div className="customer-empty">
              <img src={img} alt="" className="customer-img" />
              <h3 className="customer-title">لا يوجد طلبات</h3>
              <Button
                className="customer-btn"
                type="primary"
                onClick={() =>
                  navigate(
                    `/add/customer-product/${orderDetails?.model?.orderNumber}`
                  )
                }
              >
                اضافة
              </Button>
            </div>
          )
        )}
        {dataShopping?.length > 0 ||
        restaurant?.length > 0 ||
        externalProducts?.length > 0 ? (
          <div className="requests-detiles-shop">
            <div className="total-price">
              <h2 className="total-price-title">السعر الكلي:</h2>
              <span className="total-price-text">
                SR {orderDetails?.model?.orderTotalCost}
              </span>
            </div>
            {externalProducts?.length > 0 && (
              <>
                {externalProducts?.map((item) => (
                  <div className="shop-card-info" key={item}>
                    <div className="shop-card-header">
                      <h3 className="card-header-title">المنتجات </h3>
                      <Button
                        type="text"
                        onClick={() =>
                          setHandleEdit({
                            id: item?.id,
                            price: item?.itemPrice,
                          })
                        }
                      >
                        <EditPrice
                          handleEdit={handleEdit}
                          setHandleEdit={setHandleEdit}
                        />
                      </Button>
                    </div>
                    <div className="shop-card-data">
                      <div className="shop-data-items">
                        <div className="shop-data-item">
                          <h4 className="data-item-title">اسم المنتج:</h4>
                          <p className="data-item-text">
                            {item?.name || 'لا يوجد'}
                          </p>
                        </div>
                        <div className="shop-data-item">
                          <h4 className="data-item-title">السعر :</h4>
                          <p className="data-item-text">
                            SR {item?.itemPrice || 'لا يوجد'}
                          </p>
                        </div>
                        <div className="shop-data-item">
                          <h4 className="data-item-title">التفاصيل:</h4>
                          <p className="data-item-text">
                            {item?.details || 'لا يوجد'}
                          </p>
                        </div>
                        <div className="shop-data-item">
                          <h4 className="data-item-title">الكمية:</h4>
                          <p className="data-item-text">
                            {item?.quantity || 'لا يوجد'}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </>
            )}
            {dataShopping?.map((item) => (
              <div key={item?.id} className="shop-card-info">
                <div className="shop-card-header">
                  <h3 className="card-header-title">{item?.title}</h3>
                  <Select
                    className="select-tag"
                    defaultValue={
                      getOrderStatusStyle(selectOptions[item?.id])?.text
                    }
                    value={selectOptions[item?.id]}
                    onChange={(value) => handleChange(value, item?.id)}
                    options={options}
                    bordered={false}
                    popupMatchSelectWidth={false}
                    style={{
                      background: getOrderStatusStyle(selectOptions[item?.id])
                        ?.background,
                      color: getOrderStatusStyle(selectOptions[item?.id])
                        ?.color,
                      borderRadius: '4px',
                    }}
                  />
                </div>
                <div className="shop-card-data">
                  <>
                    <div className="shop-card-image">
                      <img src={item?.image} alt="" />
                    </div>
                    <div className="shop-data-items">
                      <div className="shop-data-item">
                        <h4 className="data-item-title">الاسم:</h4>
                        <p className="data-item-text">{item?.name}</p>
                      </div>
                      <div className="shop-data-item">
                        <h4 className="data-item-title">
                          {item?.title === 'باقة الورد'
                            ? 'سعر الباقة:'
                            : 'سعر الهدية:'}
                        </h4>
                        <p className="data-item-text">SR {item?.price}</p>
                      </div>
                      {item?.color && item?.color !== 'undefined' && (
                        <div className="shop-data-item">
                          <h4 className="data-item-title">اللون:</h4>
                          <p className="data-item-text">{item?.color}</p>
                        </div>
                      )}
                      {item?.size && item?.size !== 'undefined' && (
                        <div className="shop-data-item">
                          <h4 className="data-item-title">المقاس:</h4>
                          <p className="data-item-text">{item?.size}</p>
                        </div>
                      )}
                      {item?.description &&
                        item?.description !== 'undefined' && (
                          <div className="shop-data-item item-description ">
                            <h4 className="data-item-title">الوصف:</h4>
                            <p className="data-item-text">
                              {item?.description}
                            </p>
                          </div>
                        )}
                    </div>
                  </>
                </div>
              </div>
            ))}
            {restaurant?.map((item) => (
              <div key={item?.items?.id} className="shop-card-info">
                <div className="shop-card-header">
                  <h3 className="card-header-title">{item?.title}</h3>
                  <Select
                    className="select-tag"
                    defaultValue={
                      getOrderStatusStyle(selectOptions[item?.items?.id])?.text
                    }
                    value={selectOptions[item?.items?.id]}
                    onChange={(value) => handleChange(value, item?.items?.id)}
                    options={options}
                    bordered={false}
                    popupMatchSelectWidth={false}
                    style={{
                      background: getOrderStatusStyle(
                        selectOptions[item?.items?.id]
                      )?.background,
                      color: getOrderStatusStyle(selectOptions[item?.items?.id])
                        ?.color,
                      borderRadius: '4px',
                    }}
                  />
                </div>
                <div className="shop-card-data">
                  <>
                    <div className="shop-card-image">
                      <img src={item?.items?.image} alt="" />
                    </div>
                    <div className="shop-data-items">
                      <div className="shop-data-item">
                        <h4 className="data-item-title">اسم المطعم:</h4>
                        <p className="data-item-text">{item?.items?.name}</p>
                      </div>
                      <div className="shop-data-item">
                        <h4 className="data-item-title">عدد الاشخاص:</h4>
                        <p className="data-item-text">{item?.number}</p>
                      </div>
                      <div className="shop-data-item">
                        <h4 className="data-item-title">الوقت:</h4>
                        <p className="data-item-text">
                          <span>{item?.time}</span>
                          {getDay(item?.pm)}
                        </p>
                      </div>
                      <div className="shop-data-item">
                        <h4 className="data-item-title">تاريخ الحجز:</h4>
                        <p className="data-item-text">{item?.history}</p>
                      </div>
                    </div>
                  </>
                </div>
              </div>
            ))}
          </div>
        ) : specialProducts.length > 0 ||
          orderDetails?.model?.orderStatus === 7 ? null : (
          <div
            className="requests-detiles-shop"
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              flexDirection: 'column',
              textAlign: 'center',
              gap: '20px',
            }}
          >
            <div>
              <div className="shop-card-header">
                <h1>لا يوجد طلبات</h1>
              </div>
              <div className="shop-card-header">
                <Button type="primary" onClick={() => navigate('/')}>
                  الرجوع للصفحة الرئيسية
                </Button>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default RequestsDetiles;
