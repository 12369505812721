import React, { useState } from 'react';
import './ClientList.scss';
import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import SectionHeader from '../SectionHeader/SectionHeader';
import { Input, Table, Tag } from 'antd';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { getCustomer } from '../../network/customer';
import { queryKeys } from '../../services/react-query/queryKeys';
import dayjs from 'dayjs';

const ClientList = () => {
  const client = useQueryClient();
  const [pageCount, setPageCount] = useState(1);
  console.log(pageCount);
  const [searchText, setSearchText] = useState('');
  const {
    data: customers,
    isLoading,
    isFetching,
  } = useQuery([queryKeys.customers, pageCount], () => getCustomer(pageCount), {
    keepPreviousData: true,
  });
  console.log(customers, 'customers');
  const { totalCount, currentPage, totalPages } =
    customers?.metas?.pagination || {};
  const columns = [
    {
      title: 'رقم العميل',
      dataIndex: 'number',
      render: (text) => {
        return <span className="color-blu">{text}</span>;
      },
    },
    {
      title: 'العميل',
      dataIndex: 'name',
      filteredValue: [searchText],
      onFilter: (value, record) => {
        const name = String(record.name).toLowerCase();
        const number = String(record.number).toLowerCase();
        const searchValue = value.toLowerCase();
        // console.log(record);
        return name.includes(searchValue) || number.includes(searchValue);
      },
      render: (text) => <span className="color-blu"> {text} </span>,
    },
    {
      title: 'تاريخ الانضمام',
      dataIndex: 'History',
    },
    {
      title: 'تاريخ الميلاد',
      dataIndex: 'birth',
    },
    {
      title: 'رقم الجوال',
      dataIndex: 'phone',
      render: (text) => {
        const spanStyle = {
          color: ' #002554',
          fontSize: '12px',
          fontWeight: 700,
          lineHeight: '12px',
        };

        return <span style={spanStyle}>{text}</span>;
      },
    },
    {
      title: 'حالة الحساب',
      dataIndex: 'order',
      render: (order) => {
        let color = '';
        let background = '';

        switch (order) {
          case 'نشط':
            background = '#40C1AC1A';
            color = '#167767';
            break;
          case 'غير نشط':
            background = '#EB59591A';
            color = '#EB5959';
            break;
          default:
            break;
        }

        return (
          <Tag
            style={{
              color: color,
              background: background,
              padding: '7px 12px 8px 12px',
            }}
            className=" w-full flex justify-center items-center border-none rounded-md "
          >
            {order}
          </Tag>
        );
      },
    },
  ];
  const dataCustomers = customers?.model?.map((item) => {
    return {
      key: item?.id,
      number: item?.ordersCount,
      name: item?.name,
      History: dayjs(item?.creationDate).format('YYYY-MM-DD'),
      birth: dayjs(item?.birthDate).format('YYYY-MM-DD'),
      phone: item?.phoneNumber,
      order: item.isDeleted ? 'نشط' : 'غير نشط',
    };
  });
  const handlePageChange = (pageNumber) => {
    setPageCount(pageNumber);
    client.invalidateQueries([queryKeys.customers, pageNumber]);
  };
  const handleNextClick = () => {
    if (pageCount < totalPages) {
      setPageCount(pageCount + 1);
    }
  };

  const handlePrevClick = () => {
    if (pageCount > 1) {
      setPageCount(pageCount - 1);
    }
  };
  return (
    <>
      <SectionHeader title="العملاء" titlePath="/clients" />
      <div className="client-list position-relative">
        <Input.Search
          placeholder="البحث بـ الاسم ، رقم العميل"
          className="request-search"
          onSearch={(value) => {
            setSearchText(value);
          }}
          onChange={(e) => {
            setSearchText(e.target.value);
          }}
        />
        <Table
          columns={columns}
          dataSource={dataCustomers}
          className="position-relative"
          loading={isLoading || isFetching}
          pagination={{
            current: pageCount,
            total: totalCount,
            pageSize: 10,
            responsive: true,
            showTotal: (total, range) => {
              return `عرض ${pageCount} من اصل ${totalPages || 1} `;
            },
            showSizeChanger: false,
            itemRender: (current, type, originalElement) => {
              if (type === 'prev') {
                return (
                  <>
                    <div onClick={handlePrevClick}>
                      <RightOutlined />
                      <span>السابق</span>
                    </div>
                  </>
                );
              }
              if (type === 'next') {
                return (
                  <>
                    <div onClick={handleNextClick}>
                      <span> التالي</span>
                      <LeftOutlined />
                    </div>
                  </>
                );
              }
              return originalElement;
            },
            itemActiveBg: '#6D29F6',
            onChange: handlePageChange,
          }}
          scroll={{ x: 'max-content' }}
        />
      </div>
    </>
  );
};

export default ClientList;
