import { Menu } from 'antd';
import BrandLogo from '../../assets/images/logo.png';
import './SidebarMenu.scss';
import {
  BankOutlined,
  AppstoreOutlined,
  PieChartOutlined,
  RestOutlined,
  DollarOutlined,
  ReconciliationOutlined,
} from '@ant-design/icons';
import { IoImageOutline } from 'react-icons/io5';
import {
  PiStorefrontLight,
  PiPackageLight,
  PiFlowerTulipDuotone,
  PiTicketBold,
} from 'react-icons/pi';
import { IoMdHeartEmpty } from 'react-icons/io';
import { GrDashboard } from 'react-icons/gr';
import { LuUsers2, LuSettings } from 'react-icons/lu';
import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { AiTwotoneContainer } from 'react-icons/ai';

function SidebarMenu() {
  const navigate = useNavigate();
  // const [linkLocation, setLinkLocation] = useState("/");
  // const location = useLocation();
  const getItem = (key, label, children, icon) => ({
    key,
    label,
    children,
    icon,
  });
  const items = [
    // getItem("dashboard", "Dashboard", null, <BankOutlined />),
    // getItem("auth/forget-password", "Forget Password", null, <BankOutlined />),
    getItem('', 'لوحة التحكم', null, <GrDashboard className="navbar-icon" />),
    getItem(
      'requests',
      'الطلبات',
      null,
      <PiTicketBold className="navbar-icon" />
    ),
    getItem(
      'products',
      'المنتجات',
      null,
      <PiPackageLight className="navbar-icon" />
    ),
    getItem(
      'flowers',
      'باقات الورد',
      null,
      <PiFlowerTulipDuotone className="navbar-icon" />
    ),
    getItem(
      'restaurant',
      'المطاعم',
      null,
      <PiStorefrontLight className="navbar-icon" />
    ),
    getItem('clients', 'العملاء', null, <LuUsers2 className="navbar-icon" />),
    getItem(
      'events',
      'المناسبات',
      null,
      <IoMdHeartEmpty className="navbar-icon" />
    ),
    getItem(
      'images',
      'الصور',
      null,
      <IoImageOutline className="navbar-icon" />
    ),
    // getItem("", "الاعدادات", null, <LuSettings className="navbar-icon" />),
  ];
  return (
    <div>
      <div
        className="flex justify-center py-4 my-4 px-2 brand-logo cursor-pointer "
        onClick={() => {
          navigate('/');
        }}
      >
        <img src={BrandLogo} alt="" />
      </div>
      <Menu
        // theme="dark"
        mode="inline"
        // theme="dark"
        items={items}
        // defaultSelectedKeys={['sub2']}
        defaultSelectedKeys={[
          window.location.pathname === '/'
            ? ''
            : window.location.pathname.split('/')[1],
        ]}
        defaultOpenKeys={['sub2']}
        onClick={({ key }) => {
          console.log('getItem  key:', key);
          navigate('/' + key);
        }}
        // selectedKeys={ setLinkLocation( [location.pathname])}
        // className={
        //   location.pathname === linkLocation ? "sidebar-menu-active" : "sidebar-menu"
        // }
      />
    </div>
  );
}

export default SidebarMenu;
